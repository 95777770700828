<template>
    <div class="dashboard" v-if="loaded">
        <the-navigation/>
        <div class="dashboard__content">
            <the-header/>
            <main class="dashboard__main">
                <div class="container">
                    <slot></slot>
                </div>
            </main>
        </div>
    </div>
    <command-modal></command-modal>
</template>

<script setup>
import {onBeforeMount, ref} from 'vue';
import TheHeader from '@/components/TheHeader.vue';
import TheNavigation from '@/components/TheNavigation.vue';
import {useAuth} from "@/services/auth/auth";
import CommandModal from '@/pages/CommandModal';

const auth = useAuth();
const loaded = ref(false);

onBeforeMount(async () => {
    const {isSuccess} = await auth.fetchEmployee();

    if (isSuccess) {
        loaded.value = true;
    }
});
</script>