export default [
    {
        path: '/clients',
        name: 'clients.index',
        component: () => import('@/modules/clients/pages/clients/BrowseClients')
    },
    {
        path: '/clients/create',
        name: 'clients.create',
        component: () => import('@/modules/clients/pages/clients/AddClient'),
        meta: {
            layout: 'CommandLayout'
        }
    },
    {
        path: '/clients/:id',
        name: 'clients.show',
        component: () => import('@/modules/clients/pages/clients/ShowClient'),
        props: true,
    },
    {
        path: '/clients/edit/:id',
        name: 'clients.edit',
        component: () => import('@/modules/clients/pages/clients/EditClient'),
        props: true,
        meta: {
            layout: 'CommandLayout'
        }
    },
    {
        path: '/enrollments',
        name: 'enrollments.index',
        component: () => import('@/modules/clients/pages/enrollments/BrowseEnrollments')
    },
];