import axios from 'axios';
import {ref} from 'vue';
import useBranch from '@/services/branch/branch';
import useNotifications from '@/composables/useNotifications';

export default function useApi() {
    const branch = useBranch();
    const {showError} = useNotifications();
    const response = ref(null);
    const loading = ref(false);
    const data = ref([]);
    const errors = ref([]);
    const isSuccess = ref(null);

    const get = async (url, payload = {}) => {
        Object.assign(payload, {branchId: branch.getCurrentBranchId()});
        loading.value = true;
        isSuccess.value = null;
        try {
            const result = await axios.get(url, {params: payload});
            isSuccess.value = true;
            response.value = result;
            data.value = result.data;
        } catch (error) {
            isSuccess.value = false;
            handleError(error);
        } finally {
            loading.value = false;
        }

        return {data, isSuccess, loading}
    }

    const post = async (url, payload = {}) => {
        Object.assign(payload, {branchId: branch.getCurrentBranchId()});
        loading.value = true;
        isSuccess.value = null;
        try {
            const result = await axios.post(url, payload);
            response.value = result;
            data.value = result.data;
            isSuccess.value = true;
        } catch (error) {
            if (error.response.status === 422) {
                errors.value = parseValidationErrors(error.response.data.errors);
                showError('Podane dane są nieprawidłowe, popraw formularz i spróbuj ponownie.');
            } else {
                handleError(error);
            }

            isSuccess.value = false;
        } finally {
            loading.value = false;
        }

        return {data, isSuccess, response, errors}
    }

    const put = async (url, payload = {}) => {
        Object.assign(payload, {branchId: branch.getCurrentBranchId()});
        loading.value = true;
        isSuccess.value = null;
        try {
            const result = await axios.put(url, payload);
            response.value = result;
            data.value = result.data;
            isSuccess.value = true;
        } catch (error) {
            if (error.response.status === 422) {
                errors.value = parseValidationErrors(error.response.data.errors);
                showError('Podane dane są nieprawidłowe, popraw formularz i spróbuj ponownie.');
            } else {
                handleError(error);
            }

            isSuccess.value = false;
        } finally {
            loading.value = false;
        }

        return {isSuccess};
    }

    const destroy = async (url, payload = {}) => {
        Object.assign(payload, {branchId: branch.getCurrentBranchId()});
        loading.value = true;
        isSuccess.value = true;

        try {
            const result = await axios.delete(url, {data: {...payload}});
            response.value = result;
            data.value = result.data;
            isSuccess.value = true;
        } catch (error) {
            isSuccess.value = false;
            handleError(error);
        } finally {
            loading.value = false;
        }

        return {isSuccess};
    }

    function parseValidationErrors(errors) {
        let parsedErrors = [];

        Object.keys(errors).forEach(key => {
            parsedErrors[key] = errors[key][0];
        });

        return parsedErrors;
    }

    function handleError(error) {
        if (error.response.status === 400) {
            showError(error.response.data.error);
        } else if (error.response.status === 500) {
            showError('Wystąpił błąd serwera. Spróbuj ponownie później.');
        }
    }

    return {loading, errors, data, isSuccess, get, post, put, destroy};
}
