export default [
    {
        path: '/employees',
        name: 'employees.index',
        component: () => import('@/modules/office/pages/employees/BrowseEmployees')
    },
    {
        path: '/employees/create',
        name: 'employees.create',
        component: () => import('@/modules/office/pages/employees/AddEmployee'),
        meta: {
            layout: 'CommandLayout'
        }
    },
    {
        path: '/employees/:id',
        name: 'employees.show',
        component: () => import('@/modules/office/pages/employees/ShowEmployee'),
        props: true,
    },
];