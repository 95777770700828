import {reactive, ref} from 'vue';

const state = reactive({
    component: null,
    params: {}
});

const isActive = ref(false);

export default function useCommand() {
    const show = (component, params) => {
        state.component = component;
        state.params = params;
        isActive.value = true;
    }

    const close = () => {
        state.component = null;
        state.params = {};
        isActive.value = false;
    }

    return {
        state,
        isActive,
        show,
        close,
    }
}