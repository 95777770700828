<template>
    <div class="command" v-show="isActive">
        <a href="#" class="command__close" @click.prevent="isActive = false">
            <font-icon :icon="['fa', 'close']"/>
        </a>
        <div class="command__body">
            <component
                :is="state.component"
                v-bind="state.params"
                @cancelled="isActive = false"
                @submitted="isActive = false">
            </component>
        </div>
    </div>
</template>

<script setup>
import useCommand from "@/composables/useCommand";

const {isActive, state} = useCommand();
</script>